import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import Seo from '../components/SEO'

const post = {
  title: "Mentions légales - Arti'mouss",
  description: 'Voici le bla bla fait par des avocats pour des avocats, en cas de question envoyez moi un email !',
  image: 'https://archimouss.ch/assets/images/logo/big.png'
}


const MentionsLegales = () => {
  return (
    <>
      <Seo title={post.title} description={post.description} image={post.image}/>

    <Layout>
        <div className='main'>
            <div className='main-content'>
                <h1>Mentions Légales</h1>
                
                <h2>Editeur</h2>
                
                <p>Little Yes Sàrl<br/>Avenue du Devin-du-Village, 10<br/>1203 Genève, Suisse<br/>Capital : 20'000 CHF<br/>IDE : CHE-433.421.675</p>

                <h2>Responsable de publication</h2>
                <p>Bruno Santos Lopes<br/>Email : bruno@artimouss.ch</p>

                <h2>Hébergeur</h2>
                <p>INFOMANIAK NETWORK SA<br/>Rue Eugène-Marziano, 25<br/>1227 Les Acacias, Suisse<br/>www.infomaniak.com</p>

                <h2>Données personnelles</h2>
                <p>Se reférer à la <Link alt="Politique de confidentialité Arti'mouss" to="/politique-cookies">politique de cookies</Link></p>
            </div>
        </div>

    </Layout>
    </>
  )
}


export default MentionsLegales